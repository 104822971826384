import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {MainLayoutComponent} from '@modules/layouts/pre-login/main-layout.component';
import {HeaderComponent} from '@core/component/header/header.component';
import {FooterComponent} from '@core/component/footer/footer.component';
import {SharedCommonModule} from '@modules/shared-lib/common/common.module';
import {AppRoutingModule} from '../app-routing.module';
import {ReactiveFormsModule} from '@angular/forms';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {PostLoginLayoutComponent} from '@modules/layouts/post-login/post-login-layout.component';
import {BreadcrumbModule} from 'xng-breadcrumb';
import {NzAvatarModule} from 'ng-zorro-antd/avatar';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HeaderInterceptor} from '@interceptor/header.interceptor';
import {ProtectedApiInterceptor} from '@interceptor/protected-api.interceptor';
import {en_US, NZ_I18N} from 'ng-zorro-antd/i18n';
import {NgxsModule} from '@ngxs/store';
import {states} from '@core/store';
import {environment} from '@env';
import {NgxsStoragePluginModule, StorageOption} from '@ngxs/storage-plugin';
import {StateConstant} from '@constant/state.constant';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {AddDotsTimePeriodPipe} from '@modules/shared-lib/common/pipe/add-dots-time-period.pipe';
import {FilterListPipe} from '@modules/shared-lib/common/pipe/filter-list.pipe';
import {FormatPipe} from '@modules/shared-lib/common/pipe/format.pipe';
import {FormatNumberPipe} from '@modules/shared-lib/common/pipe/format-number.pipe';
import {DatePipe, DecimalPipe} from '@angular/common';
import {IPreloadingStrategy} from '@api/preloading';
import {
    PostBreadcrumbComponent
} from "@modules/shared-lib/common/component-revamp/post-breadcrumb/post-breadcrumb.component";

const DECLARATION = [
    MainLayoutComponent,
    PostLoginLayoutComponent,
    // header footer
    HeaderComponent,
    FooterComponent
];

const IMPORTS = [
    SharedCommonModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NzDividerModule,
    NgxsModule.forRoot(states, { developmentMode: !environment.production }),
    NgxsStoragePluginModule.forRoot({
        key: [
            StateConstant.AUTH,
            StateConstant.GLOBAL,
        ],
        storage: StorageOption.LocalStorage
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
        name: `IFAST FM WEB (${environment.testMessage})`
    }),
];

@NgModule({
    declarations: [...DECLARATION],
    imports: [...IMPORTS, BreadcrumbModule, NzAvatarModule, NzDropDownModule, PostBreadcrumbComponent],
    providers: [
        DatePipe,
        DecimalPipe,
        AddDotsTimePeriodPipe,
        FilterListPipe,
        FormatPipe,
        FormatNumberPipe,
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: HeaderInterceptor,
                    multi: true
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ProtectedApiInterceptor,
                    multi: true
                },
                {
                    provide: NZ_I18N,
                    useValue: en_US
                },
                IPreloadingStrategy
            ],
        };
    }
}

