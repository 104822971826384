<div class="w-100 h-100 bg-black-2">
    <app-main-layout-header [isPostLoginMenu]="true"></app-main-layout-header>
    <div class="print:hidden"
         [ngClass]="{
            'py-4' : showBreadcrumb,
            'pb-4' : !showBreadcrumb,
            'container': applyContainer && !(isMobile$ | async),
            'container-fluid': applyContainerFluid && !(isMobile$ | async)
         }"
    >
<!--        <div *ngIf="showBreadcrumb" class="breakcrumb-container flex {{ (isMobile$ | async)? 'px-4':'' }}" >-->
<!--        <a routerLink="/dashoard">Home</a>-->
<!--            <div class="separator">-->
<!--                <ng-container *ngTemplateOutlet="separatorTemplate"></ng-container>-->
<!--            </div>-->
<!--            <xng-breadcrumb [separator]="separatorTemplate">-->
<!--                <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info">-->
<!--                    <ng-container>{{ breadcrumb }}</ng-container>-->
<!--                    {{info}}-->
<!--                </ng-container>-->
<!--            </xng-breadcrumb>-->
<!--            <ng-template #separatorTemplate>-->
<!--               /-->
<!--            </ng-template>-->
<!--        </div>-->
        <app-post-breadcrumb *ngIf="showBreadcrumb"></app-post-breadcrumb>
        <div class="min-h-screen">
            <router-outlet></router-outlet>
        </div>
        <nz-back-top class="d-print-none" [nzTemplate]="tpl" [nzVisibilityHeight]="200"></nz-back-top>
    </div>
    <div class="card-footer d-print-none print:hidden">
        <app-main-layout-footer></app-main-layout-footer>
    </div>
</div>

<ng-template #tpl>
    <button nz-button nzType="primary" nzSize="large">
        <i nz-icon nzType="caret-up" nzTheme="outline"></i>
    </button>
</ng-template>

