import {EventEmitter, Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    pendingOrderUpdateEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor() {
    }
}
