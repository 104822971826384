import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {RouteConstant} from '@constant/route.constant';
import {MainLayoutComponent} from '@modules/layouts/pre-login/main-layout.component';
import {PageConstant} from '@constant/page.constant';
import {IRoute} from '@model/i-route.model';
import {PostLoginLayoutComponent} from '@modules/layouts/post-login/post-login-layout.component';
import {AuthGuard} from '@guard/auth.guard';
import {RightsGuard} from '@guard/rights.guard';
import {RightsConstant} from '@constant/rights.constant';

const routes: Array<IRoute> = [
    {
        path: '',
        component: MainLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./module/pages/homepage/homepage.module').then(m => m.HomepageModule),
            },
            {
                path: RouteConstant.DEVELOPER,
                loadChildren: () => import('./module/developer/developer.module').then(mod => mod.DeveloperModule),
            },
            {
                path: RouteConstant.OUR_FUNDS,
                loadChildren: () => import('./module/pages/our-funds/our-funds.module').then(m => m.OurFundsModule),
                data: {
                    nav: {
                        title: PageConstant.OUR_FUNDS,
                        link: RouteConstant.OUR_FUNDS
                    },
                    title: PageConstant.OUR_FUNDS
                }
            },
            {
                path: RouteConstant.ABOUT_US,
                loadChildren: () => import('./module/pages/about-us/about-us.module').then(m => m.AboutUsModule),
                data: {
                    nav: {
                        title: PageConstant.ABOUT_US,
                        link: RouteConstant.ABOUT_US
                    },
                    title: PageConstant.ABOUT_US
                }
            },
            {
                path: RouteConstant.INSIGHTS,
                loadChildren: () => import('./module/pages/insights/insights.module').then(m => m.InsightsModule),
                data: {
                    nav: {
                        title: PageConstant.INSIGHTS,
                        link: RouteConstant.INSIGHTS
                    },
                    title: PageConstant.INSIGHTS
                }
            },
            {
                path: RouteConstant.CONTACT_US,
                loadChildren: () => import('./module/pages/contact-us/contact-us.module').then(m => m.ContactUsModule),
                data: {
                    nav: {
                        title: PageConstant.CONTACT_US,
                        link: RouteConstant.CONTACT_US
                    },
                    title: PageConstant.CONTACT_US
                }
            },
            {
                path: RouteConstant.LOGIN,
                loadChildren: () => import('./module/auth/auth.module').then(m => m.AuthModule),
            }
        ]
    },
    {
        path: '',
        component: PostLoginLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: RouteConstant.DASHBOARD,
                canActivate: [RightsGuard],
                loadChildren: () => import('./module/pages/post-login/dashboard/dashboard.module').then(m => m.DashboardModule),
                data: {
                    breadcrumb: {
                        skip: true,
                    },
                    right: RightsConstant.DASHBOARD
                }
            },
            {
                path: RouteConstant.PORTFOLIO_MANAGEMENT,
                canActivate: [RightsGuard],
                loadChildren: () => import('./module/pages/post-login/portfolio-management/portfolio-management.module').then(m => m.PortfolioManagementModule),
                data: {
                    breadcrumb: {
                        label : PageConstant.PORTFOLIO_MANAGEMENT,
                        disable: true,
                    },
                    right: RightsConstant.PORTFOLIO_MANAGEMENT,
                }
            },
            {
                path: RouteConstant.APPROVALS,
                canActivate: [RightsGuard],
                loadChildren: () => import('./module/pages/post-login/approval/approval.module').then(m => m.ApprovalModule),
                data: {
                    breadcrumb: {
                        label : PageConstant.APPROVALS,
                        disable: true,
                    },
                    right: RightsConstant.APPROVALS,
                }
            },
            {
                path: RouteConstant.PROFILE_SETTINGS,
                canActivate:[RightsGuard],
                loadChildren: () => import('./module/pages/post-login/profile-settings/profile-settings.module').then(m => m.ProfileSettingsModule),
                data: {
                    breadcrumb: {
                        label: PageConstant.PROFILE_SETTINGS,
                        skip: true
                    },
                    right: RightsConstant.PROFILE_SETTINGS,
                }
            },
            {
                path: RouteConstant.DEVELOPER_REVAMP,
                loadChildren: () => import('./module/developer-revamp/developer.module').then(m => m.DeveloperRevampModule),
            },
        ]
    },
    {
        path: '**',
        redirectTo: '',
        pathMatch: 'full'
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
      {
          scrollPositionRestoration: 'enabled'
      })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
