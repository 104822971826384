import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ClientUrlBasic} from '@api/url-constant/ClientUrlBasic';
import {IResponse} from '@modules/shared-lib/common/model/i-response';
import {HttpService} from '../template/http.service';
import {Observable} from 'rxjs';
import {IOurFundsTableModel} from '@model/i-our-funds-table.model';
import {FundFactsheetDistributorModel, IOurFundsFactsheetModel} from '@model/i-our-funds-factsheet.model';
import {HttpParamsService} from '../template/http-params.service';
import {RestReportService} from '../general/rest-report.service';
import {tap} from 'rxjs/operators';
import {AppConstant} from '@constant/app.constant';
import {saveAs} from 'file-saver';
import {IBannerModel} from '@model/i-banner.model';
import Swal from 'sweetalert2';
import {KeyValueMapModel} from "@model/i-key-value-map.model";

@Injectable({
    providedIn: 'root'
})
export class OurFundsService extends HttpService {
    private BASE_URL: string = ClientUrlBasic.OUR_FUNDS;

    constructor(protected http: HttpClient,
                private paramService: HttpParamsService,
                private httpService: HttpService,
                private restReportService: RestReportService) {
        super(http);
    }

    getListOfFunds(mainFundsOnly?: string): Observable<IResponse<IOurFundsTableModel[]>> {
        if (mainFundsOnly !== null && mainFundsOnly !== undefined) {
            const params = this.paramService.buildHttpParams({mainFundsOnly});
            return this.postIResponseWithParam(
                this.BASE_URL + ClientUrlBasic.LIST_OF_DWS_FUNDS, params
            );
        } else {
            return this.postIResponseWithParam(
                this.BASE_URL + ClientUrlBasic.LIST_OF_DWS_FUNDS
            );
        }
    }

    getStatusSummary4(fundId): Observable<IResponse<KeyValueMapModel[]>> {
        const data = {fundId: fundId};
        return this.httpService.post(this.httpService.dataToUrl(this.BASE_URL + ClientUrlBasic.FM_FUND_DAILY_PRICE_SUMMARY, data), {});
    }

    fmFundDailyPriceDownload(title, fundId, startDate, endDate) {
        return this.restReportService.fmFundDailyPriceDownload(fundId, startDate, endDate).pipe(
            tap(arrayBuffer => {
                const blob = new Blob([arrayBuffer], {type: AppConstant.FILE_MIME_TYPE.XLS});
                saveAs(
                    blob,
                    'iFAST_FM Fund Prices ' + title +
                    '.xls'
                );
            })
        );
    }

    getAllBanners(vehicle?: string): Observable<IResponse<IBannerModel[]>> {
        const params = this.paramService.buildHttpParams({vehicle});
        return this.postIResponseWithParam(
            this.BASE_URL + ClientUrlBasic.LIST_OF_BANNER, params
        );
    }

    getFundFactsheet(fundId?: string, fundCcy?: string): Observable<IResponse<IOurFundsFactsheetModel>> {
        const params = this.paramService.buildHttpParams({fundId, fundCcy});
        return this.postIResponseWithParam(
            this.BASE_URL + ClientUrlBasic.GET_FUND_FACTSHEETS, params
        );
    }

    getListOfOtherFundsUnderThis(fundId?: string, fundCcy?: string): Observable<IResponse<IOurFundsTableModel[]>> {
        const params = this.paramService.buildHttpParams({fundId, fundCcy});
        return this.postIResponseWithParam(
            this.BASE_URL + ClientUrlBasic.GET_LIST_OTHERS, params
        );
    }

    getPerformanceChart(fundId?: string, fundCcy?: string, startDate?: string, endDate?: string, period?: string): Observable<IResponse<IOurFundsTableModel[]>> {
        const params = this.paramService.buildHttpParams({fundId, fundCcy, startDate, endDate, period});
        return this.postIResponseWithParam(
            this.BASE_URL + ClientUrlBasic.GET_PERFORM_CHART, params
        );
    }

    getPerformanceChartCentre(startDate?: string, endDate?: string, period?: string): Observable<IResponse<IOurFundsTableModel[]>> {
        const params = this.paramService.buildHttpParams({startDate, endDate, period});
        return this.postIResponseWithParam(
            this.BASE_URL + ClientUrlBasic.GET_PERFORM_CHART, params
        );
    }

    getPriceChart(fundId?: string, fundCcy?: string, startDate?: string, endDate?: string, period?: string): Observable<IResponse<IOurFundsTableModel[]>> {
        const params = this.paramService.buildHttpParams({fundId, fundCcy, startDate, endDate, period});
        return this.postIResponseWithParam(
            this.BASE_URL + ClientUrlBasic.GET_PRICE_CHART, params
        );
    }

    getPriceChartCentre(startDate?: string, endDate?: string, period?: string): Observable<IResponse<IOurFundsTableModel[]>> {
        const params = this.paramService.buildHttpParams({startDate, endDate, period});
        return this.postIResponseWithParam(
            this.BASE_URL + ClientUrlBasic.GET_PRICE_CHART, params
        );
    }

    downloadAllFundsList(title?: string) {
        return this.restReportService.downloadTableAsExcel().pipe(
            tap(arrayBuffer => {
                const blob = new Blob([arrayBuffer], {type: AppConstant.FILE_MIME_TYPE.XLS});
                saveAs(
                    blob,
                    'iFAST_FM Fund Lists ' + title +
                    '.xls'
                );
            })
        );
    }

    findProductsByKeyword(keyword: string, page: any, limit: any, displayEnabled: boolean) {
        const params = this.paramService.buildHttpParams({
            keyword,
            page,
            limit,
            displayEnabled
        });
        return this.postIResponseWithParam(
            this.BASE_URL + ClientUrlBasic.FUND_KEYWORDS, params
        );
    }

    getAllDistributor(): Observable<IResponse<FundFactsheetDistributorModel[]>> {
        return this.postIResponse(
            this.BASE_URL + ClientUrlBasic.LIST_OF_ALL_DIST
        );
    }

    downloadAnn(fileName: string) {
        const url = this.BASE_URL + ClientUrlBasic.ANNOUNCEMENT_DOWNLOAD;
        return this.downloadPdf(url, fileName);
    }

    public downloadPdf(url: string, fileName: string, data?: any) {
        return this.httpService.downloadFileWithoutSaveAs(url, fileName, 'application/pdf', true, fileName);
    }

    private downloadFile(url: string, fileName: string, fileType: string, dataByBody: boolean, data?: any) {
        let bodyData = {};
        if (dataByBody) {
            bodyData = data;
        } else {
            url = this.httpService.dataToUrl(url, data);
        }
        const httpHeaders = new HttpHeaders({
            'Accept': 'application/octet-stream'
        });
        return this.http.post(url, bodyData, {
            headers: httpHeaders,
            responseType: 'arraybuffer',
        }).pipe(
            tap(arrayBuffer => {
                const blob = new Blob([arrayBuffer], {type: fileType});
                if (arrayBuffer && arrayBuffer.byteLength > 0) {
                    saveAs(blob, fileName);
                } else {
                    console.log('Error: Empty File');
                    Swal.fire('Empty File', 'zero file generated , File Name :' + fileName, 'warning').then(() => {
                    });
                }
            })
        );
    }

    getFileNameByFundId(fundId?: string) {
        const params = this.paramService.buildHttpParams({fundId});
        return this.postIResponseWithParam(
            this.BASE_URL + ClientUrlBasic.GET_LATESTFILE_BY_FUNDID, params
        );
    }

    getAnnListByFundId(fundId?: string) {
        const params = this.paramService.buildHttpParams({fundId});
        return this.postIResponseWithParam(
            this.BASE_URL + ClientUrlBasic.GET_ALL_ANNOUNCEMENT_BY_FUNDID, params
        );
    }

    getLast30DaysAnnListByFundId(fundId?:string) {
        const params = this.paramService.buildHttpParams({fundId});
        return this.postIResponseWithParam(
            this.BASE_URL + ClientUrlBasic.GET_LAST_30DAYS_ANNOUNCEMENT_BY_FUNDID, params
        );
    }
}
