import {Injectable} from '@angular/core';
import {PaginationResult} from '@modules/shared-lib/common/model/i-pagination';
import {NzCheckBoxOptionInterface} from 'ng-zorro-antd/checkbox';
import {TableConstant} from '@constant/table.constant';

@Injectable({providedIn: 'root'})
export class GeneralService {
    static sortFn(a: any, b: any, key) {
        return GeneralService.compare(a[key] , b[key]);
    }

    // not a good approach to use frontend pagination
    static paginate<T>(data: T[], pageIndex: number, pageSize: number): PaginationResult<T> {
        const totalItems = data.length;
        const totalPages = Math.ceil(totalItems / pageSize);

        if (pageIndex < 1 || pageIndex > totalPages) {
            throw new Error(`Invalid page index. Must be between 1 and ${totalPages}.`);
        }

        const startIndex = (pageIndex - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, totalItems);

        const currentPageData = data.slice(startIndex, endIndex);

        return {
            totalPages,
            currentPage: pageIndex,
            pageSize,
            data: currentPageData,
        };
    }

    static roundTo(value, fixed: number) {
        if (value == null || value === '' || isNaN(value) || value === Infinity || value === -Infinity) {
            // console.log('roundTo: ' + value);
            value = 0;
        }
        const valueStr = value + '';
        return parseFloat(parseFloat(valueStr).toFixed(fixed));
    }

    private static compare(a, b) {
        // console.log('a-->', a);
        // console.log('b-->', b);
        // null value is - (dash)
        if (a === b) {
            return 0;
        } else if (a === null || a === undefined) {
            return -1;
        } else if (b === null || b === undefined) {
            return 1;
        }

        if (!a && a !== 0) {
            a = '-';
        }
        if (!b && b !== 0) {
            b = '-';
        }

        // whole number is date
        if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b);
        } else {
            return (a < b ? -1 : 1);
        }
    }

    static formatNumberThousandSeparator(numberObj: number, decimalPlace: number = 2): string {
        if (numberObj == null) {
            numberObj = 0;
        }
        const factor = Math.pow(10, decimalPlace);
        const strObj = (Math.round(numberObj * factor) / factor).toFixed(decimalPlace);
        return GeneralService.formatNumStringThousandSeparator(strObj);
    }

    static formatNumStringThousandSeparator(x: string): string {
        const parts = x.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    }

    static tableFilter(tableData: any[], filters: Map<string, any[]>) {
        const keys = [...filters.keys()];
        const filter = (data) => keys.every((key) => {
            const values = filters.get(key);
            if (values.length <= 0) {
                return true;
            }
            return values.includes(data[key]);
        });
        return keys.length ? tableData.filter(filter) : tableData;
    }

    static setFilterMap(filterMap: Map<string, any[]>, filterList: Map<string, NzCheckBoxOptionInterface[]>) {
        filterList.forEach((value, key) => {
            let filter;
            if (value.find(data => data.value === TableConstant.ALL).checked) {
                filter = (data) => data.value !== TableConstant.ALL;
            } else {
                filter = (data) => data.checked && data.value !== TableConstant.ALL;
            }
            const filterValues = value.filter(filter)?.map(data => data.value);

            filterMap.set(key, filterValues);
        });
    }

    static filterEmptyDataForFilterKey(filterKeyList: string[], dataList: any[]) {
        // remove filter key if the data is empty
        let finalFilterKeyList: string[] = [];
        dataList.forEach(data => {
            filterKeyList.forEach(key => {
                const value = data[key];
                if (value && !finalFilterKeyList.includes(key)) {
                    finalFilterKeyList.push(key);
                }
            });
        });
        return finalFilterKeyList;
    }

    static filterSelectionChange(filterOptions: NzCheckBoxOptionInterface[], value: string) {
        if (value !== TableConstant.ALL) {
            filterOptions.find(option => option.value === TableConstant.ALL).checked = false;
        } else {
            filterOptions.filter(option => option.value !== TableConstant.ALL)?.forEach(option => {
                option.checked = false;
            });
        }
    }

    static filterItemListProcessForCustomFilter(filterItemList: string[], dataList: any[]): any {
        const filterList = new Map<string, NzCheckBoxOptionInterface[]>();
        const filterSet = {};
        filterItemList.forEach((eachFilterItem) => {
            filterSet[eachFilterItem] = new Set();
            filterList.set(eachFilterItem, []);
        });

        dataList.forEach((eachData) => {
            filterItemList.forEach((eachFilterItem) => {
                const eachDataValue = eachData[eachFilterItem];
                if (eachDataValue) {
                    filterSet[eachFilterItem].add('' + eachDataValue);
                }
            });
        });

        filterItemList.forEach((eachFilterItem) => {
            const filterModel: NzCheckBoxOptionInterface = {
                label: TableConstant.ALL,
                value: TableConstant.ALL,
                checked: true
            };
            filterList.get(eachFilterItem).push(filterModel);

            const filterItemSet = Array.from(filterSet[eachFilterItem]);
            filterItemSet.sort((n1: string, n2: string) => n1.localeCompare(n2));
            filterItemSet.forEach((eachData) => {
                const eachFilterModel: NzCheckBoxOptionInterface = {
                    label: eachData.toString(),
                    value: eachData.toString(),
                    checked: eachData.toString().toUpperCase() === TableConstant.ALL
                };
                filterList.get(eachFilterItem).push(eachFilterModel);
            });
        });

        return filterList;
    }
}
