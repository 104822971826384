
export class ClientUrlBasic {

    /* General */
    private static  REST                    = '/rest';
    private static  DRAFT                   = '/draft';
    private static  GET                     = '/get';
    private static  FIND                    = '/find';
    private static  ADD                     = '/add';
    private static  DELETE                  = '/delete';
    private static  UPDATE                  = '/update';
    private static  TOTAL                   = '/total';
    private static  DOWNLOAD                = '/download';

    /* Authentication */
    public static  AUTH                    = ClientUrlBasic.REST + '/auth';
    public static  VERSION                  = ClientUrlBasic.AUTH + '/version';
    public static  LOGIN                    = ClientUrlBasic.AUTH + '/login';
    public static  LOGOUT                   = ClientUrlBasic.AUTH + '/logout';
    public static  PROTECTED                = '/protected';

    public static  GET_PRESESSION = '/get-presession';

    public static  CHECK = '/check';
    public static  REFRESH_TOKEN = '/refresh-token';
    public static  GET_AUTHORITIES = ClientUrlBasic.PROTECTED + '/get-authorities';

    // oauth
    public static  INTROSPECT = '/introspect';
    public static  TOKEN = '/token';
    public static  REVOKE = '/revoke';

    /* Our Funds */
    //root
    public static  OUR_FUNDS                = ClientUrlBasic.REST + '/our-funds';
    public static  LIST_OF_DWS_FUNDS        = '/list-dws-funds';
    public static  LIST_OF_ALL_DIST         = '/list-all-dist';
    public static  LIST_OF_BANNER           = '/list-main-banners';
    public static  GET_FUND_FACTSHEETS      = '/get-fund-factsheets';
    public static  FM_FUND_DAILY_PRICE_SUMMARY      = '/daily-price-summary';
    public static  FM_FUND_DAILY_PRICE_DOWNLOAD      = '/daily-price-download';
    public static  GET_PERFORM_CHART        = '/get-perform-chart';
    public static  GET_PRICE_CHART          = '/get-price-chart';
    public static  GET_LIST_OTHERS          = '/get-list-others';
    public static  DOWNLOAD_FUND_LIST       = '/download-fund-list';
    public static  FUND_KEYWORDS            = '/fund_keywords';
    public static  ANNOUNCEMENT_DOWNLOAD    = '/download-ann';
    public static  GET_LATESTFILE_BY_FUNDID    = '/get-filename-by-fundid';
    public static  GET_ALL_ANNOUNCEMENT_BY_FUNDID    = '/get-all-ann-by-fundid';
    public static  GET_LAST_30DAYS_ANNOUNCEMENT_BY_FUNDID    = '/get-30days-ann-by-fundid';

    // DashBoard
    public static  DASH_BOARD                           = ClientUrlBasic.REST + ClientUrlBasic.PROTECTED + '/dash-board';
    public static  DASH_GET_PORTFOLIO_LIST              = ClientUrlBasic.DASH_BOARD + '/get-portfolio-list';
    public static  DASH_GET_PORTFOLIO_NET_FLOW_INFO     = ClientUrlBasic.DASH_BOARD + '/get-portfolio-net-flow-info';
    public static  DASH_GET_PORTFOLIO_PERFORMANCE_INFO  = ClientUrlBasic.DASH_BOARD + '/get-portfolio-performance-info';
    public static  DASH_GET_PORTFOLIO_CHART_INFO        = ClientUrlBasic.DASH_BOARD + '/get-portfolio-chart-info';
    public static  DASH_DOWNLOAD_PORTFOLIO_SUMMARY      = ClientUrlBasic.DASH_BOARD + '/download-portfolio-summary';
    public static  DASH_GET_PORTFOLIO_SUMMARY = ClientUrlBasic.DASH_BOARD + '/get-portfolio-summary';

    // Portfolio Holding
    public static  PORTFOLIO_HOLDING = ClientUrlBasic.REST + ClientUrlBasic.PROTECTED + '/portfolio-holding';
    public static  PH_CURRENT_HOLDING = ClientUrlBasic.PORTFOLIO_HOLDING + '/get-current-holding';
    public static  PH_MATURITY_LIST = ClientUrlBasic.PORTFOLIO_HOLDING + '/get-maturity-list';
    public static  PH_MATURITY_SUMM = ClientUrlBasic.PORTFOLIO_HOLDING + '/get-maturity-summary';

    // Account
    public static  ACCOUNT                  = ClientUrlBasic.REST + '/account';
    public static  SEND_FORGET_USERNAME_EMAIL = '/send-forget-username-email';
    public static  IS_EMAIL_EXIST = ClientUrlBasic.PROTECTED + '/is-email-exist';
    public static  SEND_EMAIL_OTP = ClientUrlBasic.PROTECTED + '/send-email-otp';
    public static  SEND_TWO_FA_EMAIL_OTP = '/send-two-fa-email-otp';
    public static  SEND_SMS_OTP = ClientUrlBasic.PROTECTED + '/send-sms-otp';
    public static  AUTHENTICATE_EMAIL_OTP = ClientUrlBasic.PROTECTED + '/authenticate-email-otp';
    public static  AUTHENTICATE_SMS_OTP = ClientUrlBasic.PROTECTED + '/authenticate-sms-otp';
    public static  AUTHENTICATE_TWO_FA_OTP = '/authenticate-two-fa-otp';
    public static  REGISTER_TWO_FA_EMAIL = ClientUrlBasic.PROTECTED + '/register-two-fa-email';
    public static  RESET_PASSWORD_FIRST_TIME_LOGIN = '/reset-password-first-time-login';
    public static  VERIFY_BY_USERNAME_NRIC = '/verify-by-username-nric';
    public static  SEND_RESET_PASSWORD_EMAIL_OTP = '/send-reset-password-email-otp';
    public static  RESET_PASSWORD = '/reset-password';
    public static  GET_PENDING_ORDERS = ClientUrlBasic.PROTECTED + '/get-pending-orders';
    public static  GET_PROFILE = ClientUrlBasic.PROTECTED + '/get-profile';
    public static  EDIT_PROFILE = ClientUrlBasic.PROTECTED + '/edit-profile';
    public static  CHANGE_PASSWORD = ClientUrlBasic.PROTECTED + '/change-password';
    public static  GET_PASSWORD_UPDATED_DATE = ClientUrlBasic.PROTECTED + '/get-password-updated-date';

    // Portfolio Recommendation
    public static  PORTFOLIO_RECOMMENDATION = ClientUrlBasic.REST + ClientUrlBasic.PROTECTED + '/portfolio-recommendation';
    public static  ADD_TO_TRADE = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/add-to-trade';
    public static  UPDATE_DRAFT = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + ClientUrlBasic.DRAFT + ClientUrlBasic.UPDATE;
    public static  DELETE_DRAFT = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + ClientUrlBasic.DRAFT + ClientUrlBasic.DELETE;
    public static  GET_DRAFT = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + ClientUrlBasic.DRAFT + ClientUrlBasic.GET;
    public static  GET_TRADE_CONSTANT = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/get-trade-constant';
    public static  SUBMIT_RECOMMENDATION = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/submit-recommendation';
    public static  FIND_PENDING_RECOMMENDATIONS = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/find-pending-recommendations';
    public static  FIND_REJECTED_RECOMMENDATIONS = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/find-rejected-recommendations';
    public static  GET_DETAIL = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/detail';
    public static  DETAIL_DOWNLOAD = ClientUrlBasic.GET_DETAIL + '/download';
    public static  FIND_HISTORICAL_RECOMMENDATIONS = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/find-historical-recommendations';
    public static  PORTFOLIO_RECOMMENDATION_CHECK = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + ClientUrlBasic.CHECK;
    public static  CHECK_RECOMMENDATION_LOCK = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/check-lock';
    public static  CHECK_RECOMMENDATION_STATUS = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/check-status';
    public static  CHECK_RECOMMENDATION_LOCK_EXPIRING = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/check-lock-expiring';
    public static  LOCK_RECOMMENDATION = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/lock';
    public static  UNLOCK_RECOMMENDATION = ClientUrlBasic.PORTFOLIO_RECOMMENDATION + '/unlock';

    // Recommendation Approval
    public static  RECOMMENDATION_APPROVAL = ClientUrlBasic.REST + ClientUrlBasic.PROTECTED + '/recommendation-approval';
    public static  ADD_RECOMMENDATION_ITEM = '/add-recommendation-item';
    public static  APPROVE_RECOMMENDATION = '/approve-recommendation';
    public static  REJECT_RECOMMENDATION = '/reject-recommendation';

    // Recommendation Amendment
    public static  RECOMMENDATION_AMENDMENT = ClientUrlBasic.REST + ClientUrlBasic.PROTECTED + '/recommendation-amendment';
    public static  VOID_RECOMMENDATION = '/void-recommendation';
    public static  UPDATE_RECOMMENDATION = '/update-recommendation';
    public static  RESUBMIT_RECOMMENDATION = '/resubmit-recommendation';

    // Portfolio Info
    public static  PORTFOLIO_INFO = ClientUrlBasic.REST + ClientUrlBasic.PROTECTED + '/portfolio-info';
    public static  FIND_PORTFOLIO_OPTIONS = '/find-portfolio-options';
    public static  LIST_OF_FUND_PROFILE = '/list-fund-profile';
    public static  DOWNLOAD_FUND_PROFILE = '/download-fund-profile';
    public static  GET_MIN_PORT_INFO = '/get-min-port-info';
    public static  CASH_PROJECTION = '/cash-projection';
    public static  CASH_PROJECTION_DOWNLOAD = ClientUrlBasic.CASH_PROJECTION + ClientUrlBasic.DOWNLOAD;


    // Product Info
    public static  PRODUCT_INFO = ClientUrlBasic.REST + ClientUrlBasic.PROTECTED + '/product-info';
    public static  FIND_PRODUCT_INFO = '/find-product-info';
    public static  FIND_PORTFOLIO_ELIGIBLE_PRODUCT_TYPES = '/find-portfolio-eligible-product-types';
    public static  FIND_FD_BANKS = '/find-fd-banks';

    // Client table
    public static  CLIENT_TABLE = ClientUrlBasic.REST + ClientUrlBasic.PROTECTED + '/client-table';
    public static  GET_COL_SETUP = '/get-col-setup';
    public static  SAVE_COL_SETUP = '/save-col-setup';

    // Order Transaction
    public static  ORDER_TRANSACTION = ClientUrlBasic.REST + ClientUrlBasic.PROTECTED + '/order-transaction';
    public static  PRE_TRADE_IG_CHECK_ORDER_PAD = ClientUrlBasic.ORDER_TRANSACTION + '/pre-trade-ig-check-order-pad';
    public static  IG_GET_SUBMIT_FAILED_LIST = ClientUrlBasic.ORDER_TRANSACTION + '/ig-get-submit-failed-list';
    public static  PRE_TRADE_IG_CHECK = ClientUrlBasic.ORDER_TRANSACTION + '/pre-trade-ig-check';
    public static  IG_CHECK_DOWNLOAD = ClientUrlBasic.ORDER_TRANSACTION + '/ig-check-download';
    public static  SEND_IG_EMAIL = ClientUrlBasic.ORDER_TRANSACTION + '/send-ig-email';
    public static  PARENT_FEE_CHECK = ClientUrlBasic.ORDER_TRANSACTION + '/parent-fee-check';
    public static  ORDER_PRE_CHECK = ClientUrlBasic.ORDER_TRANSACTION + '/order-pre-check';
    public static  SUBMIT_ORDER = ClientUrlBasic.ORDER_TRANSACTION + '/submit-order';

    // Cache
    public static  CACHE = ClientUrlBasic.REST + '/cache';
    public static  FIND_ALL_CACHES = '/find-all-caches';
    public static  CHECK_CACHE = '/check-cache';
    public static  CLEAR_FX_CACHE = '/clear-fx-cache';
    public static  CLEAR_TXN_CODE_CACHE = '/clear-txn-code-cache';
    public static  CLEAR_HOLDING_CACHE = '/clear-holding-cache';

    public static  CLEAR_PORTFOLIO_INFO_CACHE = '/clear-portfolio-info-cache';
}
