import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {DynamicCssLoaderService} from '@api/service/template/load-css.service';
import { OverlayService } from '@shared/service/template/overlay.service';

@Component({
  selector: 'app-main-login-layout',
  templateUrl: './main-layout.component.html',
    styleUrls: ['./main-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MainLayoutComponent implements OnInit , OnDestroy {
    constructor(
        private cssLoader: DynamicCssLoaderService,
        private overlayService: OverlayService,
    ) {}

    ngOnInit(): void {
        this.cssLoader.loadCss('default.css', 'default-style');
        this.cssLoader.loadCss('ng-zorro-theme.css', 'ng-zorro-theme-style');
        this.overlayService.removeOverlay();
    }
    ngOnDestroy() {
        this.overlayService.displayOverlay();
        this.cssLoader.removeCss('default-style');
        this.cssLoader.removeCss('ng-zorro-theme-style');
    }
}
