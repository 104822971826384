import {Component, OnInit} from '@angular/core';
import {SubHandlingService} from '@modules/shared-lib/common/service/subscription-handling.service';
import {PopMessageService} from '@service/pop-message.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {AccountRestService} from '@api/service/api/account.rest.service';
import {VerifyUserRequestModel} from '@model/account/verify-user-request.model';
import {tap} from 'rxjs/operators';
import {IResponse} from '@modules/shared-lib/common/model/i-response';
import {AppConstant} from '@constant/app.constant';
import {ResetPasswordModalComponent} from '@modules/auth/component/reset-password-modal/reset-password-modal.component';
import {Select} from '@ngxs/store';
import {GlobalState} from '@core/store';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-forgot-password-modal',
    templateUrl: './forgot-password-modal.component.html',
    styleUrls: ['./forgot-password-modal.component.scss'],
    providers: [SubHandlingService]
})
export class ForgotPasswordModalComponent implements OnInit {
    @Select(GlobalState.isMobile) isMobile$: Observable<boolean>;
    form: FormGroup;
    submitLoading = false;
    isMobile: boolean = false;

    constructor(
        private modal: NzModalRef,
        private nzModalService: NzModalService,
        private fb: FormBuilder,
        private accountRestService: AccountRestService,
        private subHandlingService: SubHandlingService,
        private popMessageService: PopMessageService,
    ) {
    }

    ngOnInit(): void {
        this.initForm();
        this.isMobile$.subscribe((isMobile: boolean) => {
            this.isMobile = isMobile;
        })
    }

    closeModal() {
        this.modal.close();
    }

    initForm() {
        this.form = this.fb.group({
            username: [null, [Validators.required]],
            nric: [null, [Validators.required]]
        });
    }

    get usernameControl() {
        return this.form.get('username');
    }

    get nricControl() {
        return this.form.get('nric');
    }

    onChangeUsername(event: any) {
        this.usernameControl.setValue(event.toUpperCase());
    }

    verifyUsernameAndNric() {
        if (this.form.valid) {
            this.submitLoading = true;
            const verifyUserRequestModel: VerifyUserRequestModel = {
                username: this.usernameControl.value,
                nric: this.nricControl.value
            };
            this.subHandlingService.once(
                this.accountRestService.verifyEmailAndNric(verifyUserRequestModel).pipe(tap((res: IResponse<boolean>) => {
                    if (res && res.data && res.status === AppConstant.RESPONSE_SUCCESS) {
                        this.openResetPasswordModal();
                    } else {
                        this.popMessageService.openNewPopUpErrorModal('Account is not found').then();
                    }
                    this.submitLoading = false;
                }))
            );
        }
    }

    openResetPasswordModal() {
        this.closeModal();
        // use another modal component with passing component param
        this.nzModalService.create({
            nzWrapClassName: this.isMobile ? 'basic-ui-modal grey-modal' : 'basic-ui-modal grey-modal w650-screen',
            nzCentered: true,
            nzClosable: true,
            nzMaskClosable: false,
            nzContent: ResetPasswordModalComponent,
            nzComponentParams: {
                title: 'Forgot Password',
                type: 'forgotPass',
                username: this.usernameControl.value,
                nric: this.nricControl.value,
            }
        });
    }

}
