import {RouteConstant} from '@constant/route.constant';
import {INav} from '@core/interface/i-nav';
import {PageConstant} from '@constant/page.constant';
import {RightsConstant} from '@constant/rights.constant';

export const postNavData: Array<INav> = [
    {
        title: PageConstant.DASHBOARD,
        url: RouteConstant.DASHBOARD,
        right: RightsConstant.DASHBOARD,
    },
    {
        title: PageConstant.PORTFOLIO_MANAGEMENT,
        right: RightsConstant.PORTFOLIO_MANAGEMENT,
        url: RouteConstant.PORTFOLIO_MANAGEMENT,
        children: [
            {
                title: PageConstant.FUND_PROFILE,
                url: RouteConstant.PORTFOLIO_MANAGEMENT + '/' + RouteConstant.FUND_PROFILE,
                right: RightsConstant.FUND_PROFILE,
            },
            {
                title: PageConstant.PORTFOLIO_TRANSACTION,
                right: RightsConstant.PORTFOLIO_TRANSACTION,
                children: [
                    {
                        title: PageConstant.PRODUCT_ORDERPAD,
                        url: [RouteConstant.PORTFOLIO_MANAGEMENT, RouteConstant.PORTFOLIO_TRANSACTION, RouteConstant.ORDER_PAD].join('/'),
                        right: RightsConstant.ORDER_PAD,
                    },
                    {
                        title: PageConstant.HISTORICAL_ORDERS,
                        url: [RouteConstant.PORTFOLIO_MANAGEMENT, RouteConstant.PORTFOLIO_TRANSACTION, RouteConstant.HISTORICAL_ORDERS].join('/'),
                        right: RightsConstant.HISTORICAL_ORDERS,
                    }
                ],
            },
        ],
    },
    {
        title: PageConstant.APPROVAL,
        right: RightsConstant.APPROVALS,
        url: RouteConstant.APPROVALS,
        children: [
            {
                title: PageConstant.PENDING_ORDERS,
                url: [RouteConstant.APPROVALS, RouteConstant.PENDING_ORDERS].join('/'),
                right: RightsConstant.PENDING_ORDERS,
            },
            {
                title: PageConstant.ORDER_APPROVAL,
                url: [RouteConstant.APPROVALS, RouteConstant.ORDER_APPROVAL].join('/'),
                right: RightsConstant.ORDER_APPROVAL,
            }
        ],
    },
];

export const mobilePostNavData: Array<INav> = [
    {
        title: PageConstant.DASHBOARD,
        icon: 'home',
        url: RouteConstant.DASHBOARD,
        right: RightsConstant.DASHBOARD,
    },
    {
        title: PageConstant.PORTFOLIO_MANAGEMENT,
        icon: 'stock',
        right: RightsConstant.PORTFOLIO_MANAGEMENT,
        children: [
            {
                title: PageConstant.FUND_PROFILE,
                url: RouteConstant.PORTFOLIO_MANAGEMENT + '/' + RouteConstant.FUND_PROFILE,
                right: RightsConstant.FUND_PROFILE,
            }
        ],
    },
    {
        title: PageConstant.PORTFOLIO_TRANSACTION,
        icon: 'shopping-cart',
        right: RightsConstant.PORTFOLIO_TRANSACTION,
        children: [
            {
                title: PageConstant.PRODUCT_ORDERPAD,
                url: [RouteConstant.PORTFOLIO_MANAGEMENT, RouteConstant.PORTFOLIO_TRANSACTION, RouteConstant.ORDER_PAD].join('/'),
                right: RightsConstant.ORDER_PAD,
            },
            {
                title: PageConstant.HISTORICAL_ORDERS,
                url: [RouteConstant.PORTFOLIO_MANAGEMENT, RouteConstant.PORTFOLIO_TRANSACTION, RouteConstant.HISTORICAL_ORDERS].join('/'),
                right: RightsConstant.HISTORICAL_ORDERS,
            }
        ],
    },
    {
        title: PageConstant.APPROVAL,
        icon: 'check-square',
        right: RightsConstant.APPROVALS,
        children: [
            {
                title: PageConstant.PENDING_ORDERS,
                url: [RouteConstant.APPROVALS, RouteConstant.PENDING_ORDERS].join('/'),
                right: RightsConstant.PENDING_ORDERS,
            },
            {
                title: PageConstant.ORDER_APPROVAL,
                url: [RouteConstant.APPROVALS, RouteConstant.ORDER_APPROVAL].join('/'),
                right: RightsConstant.ORDER_APPROVAL,
            }
        ],
    },
]
