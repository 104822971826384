import {Component, Input} from '@angular/core';

@Component({
  selector: 'ui-media-print',
  templateUrl: './ui-media-print.component.html',
  styleUrls: ['./ui-media-print.component.scss']
})
export class UiMediaPrintComponent {

  @Input() isPrint: boolean = true;
  @Input() printingId: string = ' ';

  constructor() { }

}
