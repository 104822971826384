import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzWaveModule} from 'ng-zorro-antd/core/wave';
import {NzModalRef} from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-pop-message-modal-revamp',
  standalone: true,
  imports: [CommonModule, NzButtonModule, NzIconModule, NzWaveModule],
  templateUrl: './pop-message-modal-revamp.component.html',
  styleUrls: ['./pop-message-modal-revamp.component.less']
})
export class PopMessageModalRevampComponent implements OnInit {

  constructor(private modal: NzModalRef) { }

  @Input() type: string;
  @Input() customTypeImg: string;
  @Input() customTypeImgCss: string;
  @Input() titleText: string;
  @Input() bodyText: string;
  @Input() bodyTextCss: string;
  @Input() linkText: string;
  @Input() confirmBtn = false;
  @Input() confirmBtnHtml: string;
  @Input() cancelBtn = false;
  @Input() cancelBtnHtml: string;
  @Input() bodyTextAlignment: string;
  @Input() bodyButtonAlignment: string;
  @Input() footerButtonList: Array<{label: string, value: string, type: string}> = [];

  @Output() event: EventEmitter<any> = new EventEmitter<any>();
  @Output() linkTextClicked: EventEmitter<any> = new EventEmitter<any>();

  readonly BUTTON_TYPE = {
    default: 'default',
    primary: 'primary'
  };

  textAlignment = 'text-center';
  buttonAlignment = 'text-center';
  ngOnInit(): void {

    if (this.confirmBtn && this.cancelBtn) {
      this.textAlignment = 'text-left';
      this.buttonAlignment = 'text-right';
    }
    if (!this.confirmBtn && !this.cancelBtn) {
      this.confirmBtn = true;
    }
    if (this.bodyTextAlignment === 'left') {
      this.textAlignment = 'text-left';
    }
    if (this.bodyButtonAlignment === 'right') {
      this.buttonAlignment = 'text-right';
    }
  }

  destroyModal(): void {
    this.modal.destroy();
  }

  emitData(data: any) {
    // console.log('this is within alert box component');
    this.modal.destroy(data);
  }

  emitCustomData(value: string): void {
    this.modal.destroy({[value]: true});
  }

}
