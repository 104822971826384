export class RouteConstant {
    // Developer
    public static readonly DEVELOPER: string = 'dev';
    public static readonly DEVELOPER_REVAMP: string = 'developer';
    public static readonly STYLE_LANGUAGE: string = 'style-language';
    public static readonly BASIC_COMPONENTS: string = 'basic-components';
    public static readonly UI_COMPONENTS: string = 'ui-components';
    public static readonly STYLE_GUIDE: string = 'style-guide';

    // Basic
    public static readonly EDIT: string = 'edit';

    // context
    public static readonly CONTEXT: string = 'ifast-fm';

    // Pages
    public static readonly OUR_FUNDS: string = 'our-funds';
    public static readonly ABOUT_US: string = 'about-us';
    public static readonly INSIGHTS: string = 'insights';
    public static readonly FAQ: string = 'faq';
    public static readonly CONTACT_US: string = 'contact-us';
    public static readonly PLACEHOLDER: string = 'not-found';
    public static readonly ALL_DIST: string = 'distributors';
    public static readonly DASHBOARD: string = 'dashboard';

    // Our Funds
    public static readonly OVERVIEW: string = 'overview';

    // Others
    public static readonly PRIVACY: string = 'privacy-policy';
    public static readonly TERMS: string = 'terms-condition';
    public static readonly CAREER: string = 'career';

    // auth
    public static readonly LOGIN: string = 'login';

    // portfolio management
    public static readonly PORTFOLIO_MANAGEMENT: string = 'portfolio-management';
    public static readonly PORTFOLIO_TRANSACTION: string = 'portfolio-transaction';
    public static readonly ORDER_PAD: string = 'order-pad';
    public static readonly ORDER_PAD_SUMMARY: string = 'order-pad-summary';
    public static readonly PORTFOLIO_HOLDINGS: string = 'portfolio-holdings';
    public static readonly CASH_MANAGEMENT: string = 'cash-management';
    public static readonly FUND_PROFILE: string = 'fund-profile';
    public static readonly HISTORICAL_ORDERS: string = "historical-order";

    // approval
    public static readonly APPROVALS: string = 'approvals';
    public static readonly PENDING_ORDERS: string = 'pending-orders';
    public static readonly ORDER_APPROVAL: string = 'order-approval';

    // account settings
    public static readonly PROFILE_SETTINGS: string = 'profile-settings';
    public static readonly MY_PROFILE: string = 'my-profile';
    public static readonly CHANGE_PASSWORD: string = 'change-password';

}
