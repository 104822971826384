import {NgModule} from '@angular/core';
import {NG_ZORRO_MODULES} from './ngz.index';
import {OutsideClickDirective} from './directive/outside-click.component';
import {HighchartsChartModule} from 'highcharts-angular';
import {BasicHeaderComponent} from './component/basic-header/basic-header.component';
import {BasicContainerComponent} from './component/basic-container/basic-container.component';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {SmallChartDivComponent} from './component/small-chart-div/small-chart-div.component';
import {CategoryLogoComponent} from './component/category-logo/category-logo.component';
import {PrintButtonComponent} from './component/print-button/print-button.component';
import {SocialShareComponent} from './component/social-share/social-share.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {MobileScaffoldComponent} from './component/mobile-scaffold/mobile-scaffold.component';
import {SafeAreaComponent} from './component/safe-area/safe-area.component';
import {WebScaffoldComponent} from './component/web-scaffold/web-scaffold.component';
import {CountryFlagComponent} from './component/country-flag/country-flag.component';
import {AutofocusDirective} from './directive/ui-auto-focus.component';
import {PdfWrapperComponent} from './component/pdf-wrapper/pdf-wrapper.component';
import {FilterListPipe} from '@modules/shared-lib/common/pipe/filter-list.pipe';
import {FormatNumberPipe} from '@modules/shared-lib/common/pipe/format-number.pipe';
import { UiMediaPrintComponent } from './component-revamp/ui-media-print/ui-media-print.component';

const declaration = [
    OutsideClickDirective,
    BasicHeaderComponent,
    BasicContainerComponent,
    SmallChartDivComponent,
    CategoryLogoComponent,
    PrintButtonComponent,
    SocialShareComponent,
    WebScaffoldComponent,
    MobileScaffoldComponent,
    SafeAreaComponent,
    CountryFlagComponent,
    AutofocusDirective,
    PdfWrapperComponent,
    FilterListPipe,
    FormatNumberPipe,
    UiMediaPrintComponent,
];

const exportingModules = [
    ...NG_ZORRO_MODULES,
    OutsideClickDirective,
    HighchartsChartModule,
    BasicHeaderComponent,
    BasicContainerComponent,
    SmallChartDivComponent,
    CategoryLogoComponent,
    PrintButtonComponent,
    SocialShareComponent,
    InfiniteScrollModule,
    CommonModule,
    WebScaffoldComponent,
    MobileScaffoldComponent,
    SafeAreaComponent,
    CountryFlagComponent,
    AutofocusDirective,
    PdfWrapperComponent,
    FilterListPipe,
    FormatNumberPipe,
    UiMediaPrintComponent
];

const importModules = [
    ...NG_ZORRO_MODULES,
    HighchartsChartModule,
    InfiniteScrollModule,
    CommonModule
];

@NgModule({
    declarations: [...declaration],
    imports: [...importModules, NgOptimizedImage],
    exports: [...exportingModules],
  providers: []
})

export class SharedCommonModule {

}
